.registry-info-page {
  padding: 60px 2rem 2rem; /* Account for fixed navbar */
  min-height: 100vh;
  background-image: url("../assets/images/lookingAtKellyByBarn.jpeg");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.registry-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
}

.registry-info-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  text-align: center;
  color: var(--color-secondary);
  font-family: var(--font-secondary); /* Use secondary font */
}

.title-icon {
  font-size: 2.5rem !important;
  color: var(--color-tertiary);
}

.registry-message-box {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.instruction-section {
  margin: 2.5rem 0;
  width: 100%;
}

.instruction-card {
  text-align: center;
  padding: 2rem;
  margin: 1.5rem auto;
  max-width: 500px;
}

.instruction-icon {
  font-size: 2.5rem !important;
  color: var(--color-tertiary);
  margin-bottom: 1rem;
}

.registry-instructions {
  padding: 0;
  margin: 1.5rem 0;
}

.registry-instructions li {
  list-style: none;
  margin: 1rem 0;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
}

.contact-card {
  padding: 1.5rem;
  border-radius: 8px;
  transition: transform 0.2s ease;
}

.contact-header {
  margin-bottom: 1.5rem;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.5rem 0;
}

.contact-icon {
  color: var(--color-tertiary);
  font-size: 1.4rem !important;
  font-weight: bold;
}

.registry-info-text {
  font-family: var(--font-primary);
  color: var(--color-dark-gray);
  font-size: 1.05rem;
  line-height: 1.8;
  text-align: center;
  margin: 0 auto 2rem;
  max-width: 680px;
  font-weight: 400;
}

.registry-divider {
  border-color: var(--color-tertiary) !important;
  margin: 2rem auto;
  width: 60%;
  opacity: 0.3;
}

.special-instructions {
  padding: 2rem;
  margin: 3rem 0;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(197, 164, 103, 0.1);
}

.instruction-heading {
  font-family: var(--font-secondary);
  color: var(--color-dark-gray);
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 1.4rem;
}

.inline-icon {
  color: var(--color-tertiary);
  font-size: 1.2rem !important;
  opacity: 0.8;
}

.process-heading {
  font-family: var(--font-secondary);
  color: var(--color-dark-gray);
  text-align: center;
  margin: 2rem 0 3rem;
  font-weight: 300;
  letter-spacing: 1px;
}

.process-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
  margin: 2rem 0;
}

.process-step {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  width: 240px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(197, 164, 103, 0.15);
  transition: all 0.3s ease;
}

.process-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.step-icon {
  font-size: 2.5rem !important;
  color: var(--color-tertiary);
  margin-bottom: 1rem;
}

.step-title {
  font-family: var(--font-secondary);
  color: var(--color-dark-gray);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.step-description {
  font-family: var(--font-primary);
  color: var(--color-dark-gray);
  font-size: 0.9rem;
  line-height: 1.4;
  opacity: 0.9;
}

.process-arrow {
  font-size: 4rem;
  color: var(--color-tertiary);
  opacity: 0.8;
  margin: 0 -1rem;
  position: relative;
  top: -10px;
}
/* Here */

@media (max-width: 480px) {
  .registry-container {
    padding: 1rem;
  }
  
  .registry-info-title {
    font-size: 1.6rem;
  }
  
  .contact-item {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .registry-container {
    padding-top: 0rem;
  }
  
  .registry-message-box {
    margin-top: 6rem;
  }
  
  .registry-info-title {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
