@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Snell+Roundhand:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Georgia&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap");

:root {
  --color-primary: #ffdade; /* pink */
  --color-secondary: #fffedf; /* yellow */
  --color-tertiary: #d5e6d9; /* green */
  --color-quaternary: #fffedf; /* yellow */
  --color-visible-text: #000000; /* black */

  --color-gold: #c5a467;        /* Muted gold accent */
  --color-off-white: #f8f6f2;   /* Warm white background */
  --color-dark-gray: #2a2a2a;   /* Deep charcoal */

  --font-primary: "Cormorant Garamond", serif;
  --font-secondary: "Cinzel Decorative", cursive; /* Use Cinzel Decorative */
  --font-tertiary: "Roboto", sans-serif;
  --font-quaternary: "Snell Roundhand", cursive;
  --font-quinary: "Georgia";
  --font-logo: "Alex Brush", cursive;
}
